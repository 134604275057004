

const displayElo = (elo) => {


    if (elo < 0) {
        return `(${Math.round(-1*elo)})`
    } else {
        return Math.round(elo)
    }
    
}

export default displayElo