import React from 'react'
import { Link } from "gatsby"

import teamSummaryStyles from './team_summary.module.scss'
import DisplayEpaRank from '../../../helper_js/display_epa_ranks'
import PrevGameRow from '../../../helper_js/prev_game_row'


const teamSummary = (props) => {

    if (props.data.away == null) {

        props.data.away = {}
        props.data.away.wins = 0
        props.data.away.losses = 0
        props.data.away.draws = 0
        props.data.away.pythag_wins = 0
        props.data.away.nfelo = props.data.projectedSpreadsCsv.away_nfelo_elo
        props.data.away.nfelo_rank = null
        props.data.away.net_epa_per_play_ytd = null
        props.data.away.net_epa_per_play_ytd_rank = null
        props.data.away.epa_per_pass_ytd_rank = null
        props.data.away.epa_per_rush_ytd_rank = null
        props.data.away.epa_per_pass_against_ytd_rank = null
        props.data.away.epa_per_rush_against_ytd_rank = null
    }

    if (props.data.home == null) {

        props.data.home = {}
        props.data.home.wins = 0
        props.data.home.losses = 0
        props.data.home.draws = 0
        props.data.home.pythag_wins = 0
        props.data.home.nfelo = props.data.projectedSpreadsCsv.home_nfelo_elo
        props.data.home.nfelo_rank = null
        props.data.home.net_epa_per_play_ytd = null
        props.data.home.net_epa_per_play_ytd_rank = null
        props.data.home.epa_per_pass_ytd_rank = null
        props.data.home.epa_per_rush_ytd_rank = null
        props.data.home.epa_per_pass_against_ytd_rank = null
        props.data.home.epa_per_rush_against_ytd_rank = null

    }

    return (
        <div className={teamSummaryStyles.summaryContainer}>
            <div className={teamSummaryStyles.interiorContainer}>
                <h3 className={teamSummaryStyles.sectionHeader}> Team Summary</h3>
                <p className={teamSummaryStyles.sectionSubHeader}>
                    Team stats and rankings measure a point in time and update week to week. Elo rankings are gross of any adjustments, including QB. The downgrade for an injured QB is reflected in the EV calculations below.
                </p>
                <div className={teamSummaryStyles.matchupContainer}>
                    <table className={teamSummaryStyles.teamTable}>
                        <tbody>
                            <tr>       
                                <td colSpan={8}>
                                    <Link className={teamSummaryStyles.teamPageLink} to={`../../teams/${props.data.projectedSpreadsCsv.away_team.toLowerCase()}/${props.data.projectedSpreadsCsv.season}/`}>
                                            <img
                                                className={teamSummaryStyles.teamLogo} 
                                                src={props.data.projectedSpreadsCsv.away_team_logo_espn}
                                                alt={props.data.projectedSpreadsCsv.away_team_name}>
                                            </img>
                                    </Link>
                                </td>
                            </tr>
                            <tr> 
                                <td className={teamSummaryStyles.teamName} colSpan={8}>
                                    <Link className={teamSummaryStyles.teamPageLink} to={`../../teams/${props.data.projectedSpreadsCsv.away_team.toLowerCase()}/${props.data.projectedSpreadsCsv.season}/`}>
                                        {props.data.projectedSpreadsCsv.away_team_name}
                                    </Link>
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.teamRecord}> 
                                <td colSpan={8}>
                                    ({Math.round(props.data.away.wins)} - {Math.round(props.data.away.losses)} - {Math.round(props.data.away.draws)})  |  pythag wins: {Math.round(10*props.data.away.pythag_wins)/10}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.bottomBorderRow}>
                                <td colSpan={8}><div className={teamSummaryStyles.bottomBorderDiv}></div></td>
                            </tr>
                            <tr> 
                                <td colSpan={4}>
                                    <div className={teamSummaryStyles.statHeadBig}>nfelo</div>
                                </td>
                                <td colSpan={4}>
                                    <div className={teamSummaryStyles.statHeadBig}>Net EPA / Play</div>
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.statBig}> 
                                <td colSpan={4}>
                                    {Math.round(props.data.away.nfelo)}  ({Math.round(props.data.away.nfelo_rank)})
                                </td>
                                <td colSpan={4}>
                                    {Math.round(100*props.data.away.net_epa_per_play_ytd)/100}  ({Math.round(props.data.away.net_epa_per_play_ytd_rank)})
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.bottomBorderRow}>
                                <td colSpan={8}><div className={teamSummaryStyles.bottomBorderDiv}></div></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={teamSummaryStyles.centerCol}>
                        <p>@</p>
                    </div>
                    <table className={teamSummaryStyles.teamTable}>
                        <tbody>
                            <tr> 
                                <td colSpan={8}>
                                    <Link className={teamSummaryStyles.teamPageLink} to={`../../teams/${props.data.projectedSpreadsCsv.home_team.toLowerCase()}/${props.data.projectedSpreadsCsv.season}/`}>
                                        <img
                                            className={teamSummaryStyles.teamLogo} 
                                            src={props.data.projectedSpreadsCsv.home_team_logo_espn}
                                            alt={props.data.projectedSpreadsCsv.home_team_name}>
                                        </img>
                                    </Link>
                                </td>
                            </tr>
                            <tr> 
                                <td className={teamSummaryStyles.teamName} colSpan={8}>
                                    <Link className={teamSummaryStyles.teamPageLink} to={`../../teams/${props.data.projectedSpreadsCsv.home_team.toLowerCase()}/${props.data.projectedSpreadsCsv.season}/`}>
                                        {props.data.projectedSpreadsCsv.home_team_name}
                                    </Link>
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.teamRecord}> 
                                <td colSpan={8}>
                                    ({Math.round(props.data.home.wins)} - {Math.round(props.data.home.losses)} - {Math.round(props.data.home.draws)})  |  pythag wins: {Math.round(10*props.data.home.pythag_wins)/10}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.bottomBorderRow}>
                                <td colSpan={8}><div className={teamSummaryStyles.bottomBorderDiv}></div></td>
                            </tr>
                            <tr> 
                                <td colSpan={4}>
                                    <div className={teamSummaryStyles.statHeadBig}>nfelo</div>
                                </td>
                                <td colSpan={4}>
                                    <div className={teamSummaryStyles.statHeadBig}>Net EPA / Play</div>
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.statBig}> 
                                <td colSpan={4}>
                                    {Math.round(props.data.home.nfelo)}  ({Math.round(props.data.home.nfelo_rank)})
                                </td>
                                <td colSpan={4}>
                                    {Math.round(100*props.data.home.net_epa_per_play_ytd)/100}  ({Math.round(props.data.home.net_epa_per_play_ytd_rank)})
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.bottomBorderRow}>
                                <td colSpan={8}><div className={teamSummaryStyles.bottomBorderDiv}></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={teamSummaryStyles.spacer}></div>
                <div className={teamSummaryStyles.tableHolder}>
                    <table className={teamSummaryStyles.summaryTable}>
                        <tbody>
                            <tr className={teamSummaryStyles.sectionHeader}>
                                <td style={{width: '60%'}}>
                                    EPA Ranks
                                </td>
                                <td style={{width: '20%'}}>
                                    <div style={{verticalAlign : 'center',}}>
                                        <img
                                            className={teamSummaryStyles.team_logo}
                                            src={props.data.projectedSpreadsCsv.away_team_logo_espn}
                                            alt={props.data.projectedSpreadsCsv.away_team_name}>
                                        </img>
                                    </div>
                                </td>
                                <td style={{width: '20%'}}>
                                    <div style={{verticalAlign : 'center',}}>
                                        <img
                                            className={teamSummaryStyles.team_logo}
                                            src={props.data.projectedSpreadsCsv.home_team_logo_espn}
                                            alt={props.data.projectedSpreadsCsv.home_team_name}>
                                        </img>
                                    </div>
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowMain}>
                                <td>
                                    Offense
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_play_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_play_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowSub}>
                                <td>
                                    Pass
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_pass_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_pass_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowSub}>
                                <td>
                                    Run
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_rush_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_rush_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowMain}>
                                <td>
                                    Defense
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_play_against_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_play_against_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowSub}>
                                <td>
                                    Pass
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_pass_against_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_pass_against_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamSummaryStyles.sectionDataRowSub}>
                                <td>
                                    Run
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.away.epa_per_rush_against_ytd_rank)}
                                </td>
                                <td>
                                    {DisplayEpaRank(props.data.home.epa_per_rush_against_ytd_rank)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={teamSummaryStyles.spacer}></div>
                <div className={teamSummaryStyles.lastFiveContainer}>
                    <div className={teamSummaryStyles.teamLastFiveContainer}>
                        <p className={teamSummaryStyles.lastFiveTitle}>{props.data.projectedSpreadsCsv.away_team_name} Last 5 Games</p>
                        <table className={teamSummaryStyles.lastFiveTable}>
                            <thead>
                                <tr className={teamSummaryStyles.prevGameHeader}>
                                    <th>
                                        Opp
                                    </th>
                                    <th>
                                        Result
                                    </th>
                                    <th>
                                        Margin
                                    </th>
                                    <th>
                                        Line
                                    </th>
                                    <th>
                                        ATS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.awayPrevGames.nodes.map(node => {
                                    return (
                                        <PrevGameRow 
                                            opp={node.opp}
                                            game_id={node.game_id_slug}
                                            home_spread_line={node.home_spread_line}
                                            points_for={node.points_for}
                                            points_against={node.points_against}
                                            result={node.result}
                                            spread_result={node.spread_result}
                                            logo={node.team_logo_espn}
                                            location={node.location}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={teamSummaryStyles.teamLastFiveContainer}>
                        <p className={teamSummaryStyles.lastFiveTitle}>{props.data.projectedSpreadsCsv.home_team_name} Last 5 Games</p>
                        <table className={teamSummaryStyles.lastFiveTable}>
                        <thead>
                                <tr className={teamSummaryStyles.prevGameHeader}>
                                    <th>
                                        Opp
                                    </th>
                                    <th>
                                        Result
                                    </th>
                                    <th>
                                        Margin
                                    </th>
                                    <th>
                                        Line
                                    </th>
                                    <th>
                                        ATS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.homePrevGames.nodes.map(node => {
                                    return (
                                        <PrevGameRow 
                                            opp={node.opp}
                                            game_id={node.game_id_slug}
                                            home_spread_line={node.home_spread_line}
                                            points_for={node.points_for}
                                            points_against={node.points_against}
                                            result={node.result}
                                            spread_result={node.spread_result}
                                            logo={node.team_logo_espn}
                                            location={node.location}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default teamSummary