import React from 'react'
import { Link } from "gatsby"

import displayElo from '../../../helper_js/display_elo_adj'
import displayEloColor from '../../../helper_js/display_elo_adj_color'
import spreadCalcStyles from './spread_calc.module.scss'

import CalcEv from '../../../helper_js/calc_ev'

const spread_calc = (props) => {

    return (
        <div className={spreadCalcStyles.spreadCalcContainer}>
            <div className={spreadCalcStyles.interiorContainer}>
                <h3 className={spreadCalcStyles.sectionHeader}> EV Calculation</h3>
                <p className={spreadCalcStyles.sectionSubHeader}>
                    Difference in nfelo ratings (ie Power Ratings) is adjusted for context, regressed to the market, and translated to a spread. This spread is then used to calculate an EV based on modeled W/L/P probabilities.
                </p>
                <table className={spreadCalcStyles.difCalcTable}>
                    <colgroup>
                        <col width='5%' max-width='5%'></col>
                        <col width='50%' max-width='50%'></col>
                        <col width='30%' max-width='30%'></col>
                        <col width='15%' max-width='15%'></col>
                    </colgroup>
                    <tr className={spreadCalcStyles.row}>
                        <td></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Home nfelo
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue}>
                            {Math.round(props.data.projectedSpreadsCsv.home_nfelo_elo)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}>-</td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Away nfelo
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue}>
                            {Math.round(props.data.projectedSpreadsCsv.away_nfelo_elo)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.totalRow}>
                        <td className={spreadCalcStyles.calcOperator}></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Unadj Difference
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                        ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_nfelo_elo - props.data.projectedSpreadsCsv.away_nfelo_elo)}>
                        {displayElo(props.data.projectedSpreadsCsv.home_nfelo_elo - props.data.projectedSpreadsCsv.away_nfelo_elo)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.spacer}>
                        <td className={spreadCalcStyles.spacer}>.</td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}>+</td>
                        <td className={spreadCalcStyles.calcComponent}>
                            HFA Adj <Link
                                className={spreadCalcStyles.learnMore}
                                to={"/analysis/an-initial-exploration-of-home-field-advantage-in-the-nfl/"}>&#9656;
                            </Link>
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_net_HFA_mod)}>
                            {displayElo(props.data.projectedSpreadsCsv.home_net_HFA_mod)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}>+</td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Net Rest Adj
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_net_bye_mod)}>
                            {displayElo(props.data.projectedSpreadsCsv.home_net_bye_mod)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}>+</td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Net QB Adj
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_net_qb_mod)}>
                            {displayElo(props.data.projectedSpreadsCsv.home_net_qb_mod)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.totalRow}>
                        <td className={spreadCalcStyles.calcOperator}></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Adj Difference
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                        ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_dif_pre_reg)}>
                            {displayElo(props.data.projectedSpreadsCsv.home_dif_pre_reg)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.spacer}>
                        <td className={spreadCalcStyles.spacer}>.</td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Market Dif
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.home_line_close * -25)}>
                            {displayElo(props.data.projectedSpreadsCsv.home_line_close * -25)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.row}>
                        <td className={spreadCalcStyles.calcOperator}></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Market Reg. Factor
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                            ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue}>
                            {Math.round(100*props.data.projectedSpreadsCsv.market_regression_factor)/100}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.totalRow}>
                        <td className={spreadCalcStyles.calcOperator}></td>
                        <td className={spreadCalcStyles.calcComponent}>
                            Regressed Dif
                        </td>
                        <td className={spreadCalcStyles.calcDivider}>
                        ................................................
                        </td>
                        <td className={spreadCalcStyles.calcValue} style={displayEloColor(props.data.projectedSpreadsCsv.regressed_dif)}>
                            {displayElo(props.data.projectedSpreadsCsv.regressed_dif)}
                        </td>
                    </tr>
                    <tr className={spreadCalcStyles.spacer}>
                        <td className={spreadCalcStyles.spacer}>.</td>
                    </tr>
                </table>
                <div className={spreadCalcStyles.evContainer}>
                    <p className={spreadCalcStyles.stepHeader}>
                        Translate nfelo difference to a WP% and spread
                    </p>
                    <table className={spreadCalcStyles.spreadTable}>
                        <colgroup>
                            <col width='40%' max-width='40%'></col>
                            <col width='20%' max-width='20%'></col>
                            <col width='20%' max-width='20%'></col>
                            <col width='20%' max-width='20%'></col>
                        </colgroup>
                        <thead>
                            <tr className={spreadCalcStyles.evTableHeaderRow}>
                                <th>
                                    Team
                                </th>
                                <th>
                                    nfelo Dif &#10145;
                                </th>
                                <th>
                                    Win Prob &#10145;
                                </th>
                                <th>
                                    nfelo Spread
                                </th>
                            </tr>
                        </thead>
                        <tr>
                            <td>
                                {props.data.projectedSpreadsCsv.home_team_name}
                            </td>
                            <td>
                                {displayElo(props.data.projectedSpreadsCsv.regressed_dif)}
                            </td>
                            <td>
                                {Math.round(100*props.data.projectedSpreadsCsv.home_probability_nfelo)}%
                            </td>
                            <td>
                                {Math.round(100*props.data.projectedSpreadsCsv.home_closing_line_rounded_nfelo)/100}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {props.data.projectedSpreadsCsv.away_team_name}
                            </td>
                            <td>
                                {displayElo(-1 * props.data.projectedSpreadsCsv.regressed_dif)}
                            </td>
                            <td>
                                {Math.round(100*(1-props.data.projectedSpreadsCsv.home_probability_nfelo))}%
                            </td>
                            <td>
                                {Math.round(-100*props.data.projectedSpreadsCsv.home_closing_line_rounded_nfelo)/100}
                            </td>
                        </tr>
                    </table>
                    <p className={spreadCalcStyles.stepHeader}>
                        Translate model spread into an expected bet value (EV)<Link 
                            className={spreadCalcStyles.learnMore}
                            to={"/analysis/margin-probabilities-from-nfl-spreads/"}>&#9656;
                        </Link>
                    </p>
                    <p className={spreadCalcStyles.evTableTitle}>
                        {props.data.projectedSpreadsCsv.home_team_name}
                    </p>
                    <table className={spreadCalcStyles.spreadTable}>
                        <thead>
                            <tr className={spreadCalcStyles.evTableHeaderRow}>
                                <th>
                                Model Line 
                                </th>
                                <th>
                                Market Line 
                                </th>
                                <th>
                                Loss % 
                                </th>
                                <th>
                                Push % 
                                </th>
                                <th>
                                Cover % 
                                </th>
                                <th>
                                Price 
                                </th>
                                <th>
                                EV ($) 
                                </th>
                                <th>
                                EV (%) 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <CalcEv 
                                data={props.data}
                                price={105}
                                is_home={true}
                            />
                            <CalcEv 
                                data={props.data}
                                price={110}
                                is_home={true}
                            />
                            <CalcEv 
                                data={props.data}
                                price={115}
                                is_home={true}
                            />
                        </tbody>
                    </table>
                    <p className={spreadCalcStyles.evTableTitle}>
                        {props.data.projectedSpreadsCsv.away_team_name}
                    </p>
                    <table className={spreadCalcStyles.spreadTable}>
                        <thead>
                            <tr className={spreadCalcStyles.evTableHeaderRow}>
                                <th>
                                Model Line 
                                </th>
                                <th>
                                Market Line 
                                </th>
                                <th>
                                Loss % 
                                </th>
                                <th>
                                Push % 
                                </th>
                                <th>
                                Cover % 
                                </th>
                                <th>
                                Price 
                                </th>
                                <th>
                                EV ($) 
                                </th>
                                <th>
                                EV (%) 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <CalcEv 
                                data={props.data}
                                price={105}
                                is_home={false}
                            />
                            <CalcEv 
                                data={props.data}
                                price={110}
                                is_home={false}
                            />
                            <CalcEv 
                                data={props.data}
                                price={115}
                                is_home={false}
                            />
                            <tr className={spreadCalcStyles.spacer}>
                                <td className={spreadCalcStyles.spacer}>.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )

}

export default spread_calc