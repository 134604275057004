

const displayEloColor = (elo) => {

    const neg_text = '#e86888'
    const pos_text = '#65e0b7'

    if (elo <= 0) {
        return {
            color : neg_text
        }
    } else {
        return {
            color : pos_text
        }
    }
    
}

export default displayEloColor