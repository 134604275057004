import React from 'react'
import { Link } from "gatsby"

import DisplayResult from './display_game_result'
import DisplayWL from './display_win_loss'
import DisplayOpp from './display_opponent'

import teamSummaryStyles from '../templates/modals/modal_components/team_summary.module.scss'

const PrevGameRow = ( { opp, game_id, home_spread_line, points_for, points_against, result, spread_result, logo, location } ) => {

        var backColor = ''

        if (spread_result === 'L') {
            backColor = '#fffafc'
        } else {
            backColor = '#fafffd'
        }

        return (
            <tr className={teamSummaryStyles.prevGameRow} style={{backgroundColor : backColor}}>    
                <td style={{width:'15%'}}>
                    <Link className={teamSummaryStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={teamSummaryStyles.linkDiv}>
                            <DisplayOpp
                                logo={logo}
                                opp_name={opp}
                                location={location}
                            />
                        </div>
                    </Link>
                </td>
                <td style={{width:'30%'}}>
                    <Link className={teamSummaryStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={teamSummaryStyles.linkDiv}>
                            <DisplayResult
                                result={result}
                                pf={points_for}
                                pa={points_against}
                            />
                        </div>
                    </Link>
                </td>
                <td>
                    <Link className={teamSummaryStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={teamSummaryStyles.linkDiv}>
                            {parseInt(points_for-points_against)}
                        </div>
                    </Link>
                </td>
                <td>
                    <Link className={teamSummaryStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={teamSummaryStyles.linkDiv}>
                            {home_spread_line}
                        </div>
                    </Link>
                </td>
                <td>
                    <Link className={teamSummaryStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={teamSummaryStyles.linkDiv}>
                            <DisplayWL
                                result={spread_result}
                            />
                        </div>
                    </Link>
                </td>
            </tr>
        )

}

export default PrevGameRow


