import React from 'react'
import { graphql } from 'gatsby'


import Layout from '../../components/layout'
import Head from '../../components/head'

import modalStyles from './modal.module.scss'
import SpreadCalc from './modal_components/spread_calc'
import TeamSummary from './modal_components/team_summary'


export const query = graphql`
    query (
        $game_id: String!
        $week: String!
        $season: String!
        $home_team: String!
        $away_team: String!
    ) {
        projectedSpreadsCsv(
            game_id: {
                eq: $game_id
            }
        ) {
            season
            week
            home_team
            away_team
            home_team_name
            away_team_name
            home_team_logo_espn
            away_team_logo_espn
            home_nfelo_elo
            away_nfelo_elo
            home_dif
            home_dif_pre_reg
            regressed_dif
            market_regression_factor
            home_net_qb_mod
            home_net_HFA_mod
            home_net_bye_mod
            home_line_close
            home_closing_line_rounded_nfelo
            home_ev
            away_ev
            home_probability_nfelo
            home_loss_prob
            home_push_prob
            home_cover_prob
        }
        home: weekFileCsv(
            season: {
                eq: $season
            }
            week: {
                eq: $week
            }
            team: {
                in: [$home_team]
            }
        ) {
            season
            week
            team
            wins
            losses
            draws
            pythag_wins
            nfelo
            nfelo_rank
            epa_per_play_ytd
            epa_per_play_ytd_rank
            net_epa_per_play_ytd
            net_epa_per_play_ytd_rank
            epa_per_play_against_ytd_rank
            epa_per_pass_ytd
            epa_per_pass_ytd_rank
            epa_per_pass_against_ytd_rank
            epa_per_rush_ytd
            epa_per_rush_ytd_rank
            epa_per_rush_against_ytd_rank
        }
        away: weekFileCsv(
            season: {
                eq: $season
            }
            week: {
                eq: $week
            }
            team: {
                in: [$away_team]
            }
        ) {
            season
            week
            team
            wins
            losses
            draws
            pythag_wins
            nfelo
            nfelo_rank
            epa_per_play_ytd
            epa_per_play_ytd_rank
            net_epa_per_play_ytd
            net_epa_per_play_ytd_rank
            epa_per_play_against_ytd_rank
            epa_per_pass_ytd
            epa_per_pass_ytd_rank
            epa_per_pass_against_ytd_rank
            epa_per_rush_ytd
            epa_per_rush_ytd_rank
            epa_per_rush_against_ytd_rank
        }
        homePrevGames: allGameHistoryCsv(
            limit : 5
            sort: {
                fields: game_id,
                order: DESC
            }
            filter: {
              team: {
                in: [$home_team]
              }
            }
        ) {
            nodes {
              season
              week
              team
              opp
              points_for
              points_against
              home_spread_line
              result
              spread_result
              game_id
              game_id_slug
              team_logo_espn
              location
            }
        }
        awayPrevGames: allGameHistoryCsv(
            limit : 5
            sort: {
                fields: game_id,
                order: DESC
            }
            filter: {
              team: {
                in: [$away_team]
              }
            }
        ) {
            nodes {
              season
              week
              team
              opp
              points_for
              points_against
              home_spread_line
              result
              spread_result
              game_id
              game_id_slug
              team_logo_espn
              location
            }
        }
    }
    `

const Games = (props) => {

    return (
        <Layout>
            <Head
                title={`${props.data.projectedSpreadsCsv.away_team_name} @ ${props.data.projectedSpreadsCsv.home_team_name} Betting Preview and Prediction`}
                pageDescription={`Betting preview, odds, spread, and predictions for ${props.data.projectedSpreadsCsv.away_team_name} @ ${props.data.projectedSpreadsCsv.home_team_name}`}/>
            <div className={modalStyles.modalContainer}>
                <div>
                    <TeamSummary data={props.data}/>
                </div>
                <div className={modalStyles.spacer}></div>
                <div>
                    <SpreadCalc data={props.data}/>
                </div>
            </div>
        </Layout>
    )
}

export default Games
