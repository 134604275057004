import React from "react"
import tinycolor from 'tinycolor2'

const CalcEv = (props) => {

    const neg_color = '#ffabc0'
    const zero_color = '#ffffff'
    const pos_color = '#96ffdd'
    const pos_text = '#87e6c7'

    if (props.is_home) {
        var ev = Math.round(100 * 
            (
                props.data.projectedSpreadsCsv.home_loss_prob * -1 * props.price +
                props.data.projectedSpreadsCsv.home_cover_prob * 100
            )
        ) / 100

        var line = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_closing_line_rounded_nfelo
        ) / 100

        var marketLine = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_line_close
        ) / 100

        var lossProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_loss_prob
        )

        var pushProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_push_prob
        )

        var winProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_cover_prob
        )

    } else {
        ev = Math.round(100 * 
            (
                props.data.projectedSpreadsCsv.home_cover_prob * -1 * props.price +
                props.data.projectedSpreadsCsv.home_loss_prob * 100
            )
        ) / 100

        line = Math.round(100 *
            -1 * props.data.projectedSpreadsCsv.home_closing_line_rounded_nfelo
        ) / 100

        marketLine = Math.round(100 * 
            -1 * props.data.projectedSpreadsCsv.home_line_close
        ) / 100

        lossProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_cover_prob
        )

        pushProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_push_prob
        )

        winProb = Math.round(100 * 
            props.data.projectedSpreadsCsv.home_loss_prob
        )

    }

    if (ev <= 0) {
        var evStyle = {
            backgroundColor : tinycolor.mix(zero_color, neg_color, Math.abs(200 * ev / props.price)),
            color : tinycolor.mix(zero_color, neg_color, Math.abs(100 * ev / props.price-.25) * 400)
        }
    } else {
        evStyle = {
            backgroundColor : tinycolor.mix(zero_color, pos_color, Math.abs(200 * ev / props.price)),
            color : pos_text
        }
    }
    
    return (
        <tr>
            <td>
                {line}
            </td>
            <td>
                {marketLine}
            </td>
            <td style={{borderLeft : '2px double #525252',}}>
                {lossProb}%
            </td>
            <td>
                {pushProb}%
            </td>
            <td style={{borderRight : '2px double #525252',}}>
                {winProb}%
            </td>
            <td>
                {props.price}
            </td>
            <td>
                ${ev}
            </td>
            <td style={evStyle}>
                {Math.round(1000 * ev / props.price)/10}%
            </td>
        </tr>
    )
}

export default CalcEv